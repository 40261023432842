// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": `${process.env.APPSYNC_REGION}`,
    "aws_appsync_graphqlEndpoint": `${process.env.API_URL}`, //DEV
    //"aws_appsync_graphqlEndpoint": "https://2sgsgbudifan7cmikz66tyxpzi.appsync-api.us-east-1.amazonaws.com/graphql", //PROD
    "aws_appsync_region": `${process.env.APPSYNC_REGION}`,
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "Auth": {
        "region": `${process.env.APPSYNC_AUTH_REGION}`,
        "userPoolWebClientId": `${process.env.AUTH_CLIENT_ID}`,
        "userPoolId": `${process.env.AUTH_USER_POOL_ID}`
    }
};



export default awsmobile;
