import React,{useEffect, useState} from "react"
import { Auth } from 'aws-amplify';

const NavBar = ({logo}) => {
  //force component refresh after 5 seg (so that the user can see the new state)
  const [forceUpdate, setForceUpdate] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setForceUpdate(forceUpdate + 1);
    }, 2000);
  }, [forceUpdate]);
  
    return (
      <div>
         <div class="d-flex flex-row bd-highlight mb-3" style={{boxShadow:'0px 2px 5px grey'}}>
                <img src={logo} style={{width:'150px',height:'auto',marginBottom:'0px',marginLeft:'10px'}}></img>
                {Auth.user&&<div class="p-2 bd-highlight" style={{fontSize:'16px',marginLeft:'auto',marginRight:'10px',alignSelf:'flex-end'}}>
                  Bienvenido <strong>{Auth.user.username}!</strong>
                </div>}
            </div>
      </div>
    );
  }


export default NavBar

