/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useSelector,shallowEqual} from 'react-redux'
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory as history } from '@reach/router'
import { Authenticator,SignUp,AuthPiece,Greetings } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports'; 
import { I18n } from 'aws-amplify';
import logo from "../../static/logo.png";
import Navbar from './dormis/navbar/navbar'
import 'react-table-6/react-table.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'moment/locale/es'  // without this line it didn't work
import "./layout.css"
import "./barcode.css"


const authScreenLabels = {
  es: {
      'Sign In': 'Entrar',
      'Sign Up': 'Crear nueva cuenta',
      'Sign Up Account': 'Crear una cuenta nueva',
      'Enter your username': "Ingresa tu nombre de usuario",
      'Enter your password': 'Ingresa tu contraseña',
      'Forget your password? ': '¿Has olvidado tu contraseña? ',
      'Reset password': 'Recuperar contraseña',
      'Sign in to your account': 'Ingresa a tu cuenta',
      'Reset your password': 'Recuperar contraseña',
      'User does not exist': 'El usuario no existe',
      'User already exists': 'El usuario ya existe',
      'Incorrect username or password': 'Contraseña incorrecta',
  }
};
I18n.setLanguage('es');
I18n.putVocabularies(authScreenLabels);

const federated = {
  google_client_id: 'dsds',
  facebook_app_id: '',
  amazon_client_id: ''
};
Amplify.configure(awsconfig)
const MyTheme = {
  signInButtonIcon: { 'display': 'none' },
  signUpButtonIcon: { 'display': 'none' },
  signInButton: { 'backgroundColor': 'red', 'borderColor': 'red' }
}
class Private extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signedIn'];
  }
  componentDidMount () {
    const script = document.createElement("script");
    script.src = "/dbr.js";
    document.body.appendChild(script);
    const script2 = document.createElement("script");
    script2.src = "/scannerInit.js";
    document.body.appendChild(script2);
}

  showComponent(theme){
    return(
    <>
      <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
      <main style={{textAlign:"center"}}>{this.props.children}</main>
    </>
  )}
}

const { location } = history
const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const isMobile = useSelector(state => state.mainReducer.isMobile)

  return (
    <>
    {!isMobile&&<Navbar
        isLoading={false}
        logo={logo}
      />}
    <Authenticator  onStateChange={(e)=>{if(e=='signIn'){window.signInState=true};if(e=='signedIn'&&window.signInState){window.location.reload();}}} theme={{signUpButton: { 'backgroundColor': 'blue', 'borderColor': 'blue' }}} hide={[SignUp,Greetings]}>
      <Private children={children} siteTitle={data.site.siteMetadata.title}></Private>
    </Authenticator> 
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


